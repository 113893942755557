import React from "react";
import { useParams } from "react-router-dom";
import "./TeamMemberPage.css";

const teamMembers = [
    {
        name: "Ganesh Manjeshwara Padakannaya",
        title: "Chief Executive Officer",
        description: "Ganesh is a distinguished Chartered Accountant (ICAI) and Company Secretary (ICSI) with an impressive track record of excellence in finance, accounting, and business advisory services. Holding a Bachelor’s degree in Business Management and a Law degree, he brings over two decades of professional experience. Ganesh has served in pivotal roles, including International Finance Manager and senior leadership positions at esteemed organizations such as Religare and Boston Financials, demonstrating a profound ability to drive financial strategy and operational success.",
        image: "/images/ganesh.jpg",
    },
    {
        name: "April Van Horn",
        title: "Vice President",
        description: "April is a dynamic and results-driven accounting professional with extensive expertise in cash flow forecasting and complex reconciliations. She brings a strong background in managing accounts receivable and payable, while ensuring seamless interdepartmental coordination. April’s experience includes implementing advanced software systems and enhancing internal controls to drive operational efficiency. She has held key positions at MON-YOUGH Community Services, Management Science Associates, and leading manufacturing industries, consistently delivering excellence in financial management and process optimization.",
        image: "/images/april.jpg",
    },
    {
        name: "Kawshal G",
        title: "Vice President – Corporate Compliance",
        description: "Kawshal is an accomplished corporate compliance professional and an associate member of ICSI, with a Bachelor's degree in Accounting. He brings extensive expertise in corporate governance across the US and India, specializing in company incorporations, legal documentation, and statutory compliance. Kawshal has held key positions at Ullas Kumar Associates and Boston Financials, where he played a pivotal role in ensuring regulatory adherence and facilitating seamless corporate operations. His meticulous approach and in-depth knowledge make him a valuable asset in navigating complex compliance frameworks.",
        image: "/images/kawshal.jpg",
    },
    {
        name: "Sanjeev Sunkad",
        title: "Senior Manager - Operations",
        description: "Sanjeev is a semi-qualified Chartered Accountant with over 10 years of experience in Accounts, Finance, and Audit functions. Holding a Bachelor’s degree in Business Administration (BBA) from Karnataka University, he specializes in Accounting Advisory, Indirect Taxes, Statutory Audits, and Tax Compliance. Sanjeev is recognized for his expertise in financial accounting, payroll preparation, financial analysis, and risk management, alongside strong leadership and communication skills. His professional achievements include receiving client awards for financial reporting, enhancing team efficiency by streamlining processes, and successfully managing accounting teams during new branch setups. Sanjeev has held roles in In2talk Technologies, Chhabra Group, Consero Solutions, and Rajagopal & Badri Narayanan.",
        image: "/images/sanjeev.jpg",
    },
]; 
const advisoryMembers = [
    {
        name: "Krishna Padakannaya",
        description: "Krishna is a highly experienced finance and management professional, specializing in outsourcing, financial due diligence, and mergers & acquisitions (M&A). A Chartered Accountant (ICAI) with a Bachelor's degree in Business Management and a Law degree, Krishna has a proven track record of leading finance operations across APAC, US, and EMEA regions. He has held senior leadership positions at renowned organizations, including Price Waterhouse, Emmvee, MetricStream, and Concentrix, consistently driving strategic financial initiatives and delivering exceptional results.",
        image: "/images/krishna.jpg",
    },
    {
        name: "Dr Deepak Pandey",
        description: "Deepak Pandey, Ph.D., is a visionary leader with expertise in physics, materials science, and organizational strategy. With a Ph.D. in novel carbon-based materials applied to energy, electronics, and medical devices, his research has been published across diverse fields. Over his career, Deepak has held leadership roles in sales, operations, supply chain, and product development, driving transformative growth through innovative systems and processes. As an entrepreneur, he has successfully launched and scaled both service- and product-based businesses.As an advisor to Nexusglobe, Deepak brings a strategic perspective and a deep understanding of scaling operations and building impactful partnerships. His ability to bridge technical expertise with business leadership aligns seamlessly with Nexusglobe’s mission to deliver exceptional finance and accounting solutions that help businesses thrive in a competitive landscape.",
        image: "/images/deepak.jpg",
    },
]; 

function TeamMemberPage() {
    const { id } = useParams();
    const isAdvisory = window.location.pathname.includes("advisory");
    const member = isAdvisory ? advisoryMembers[id] : teamMembers[id];

    if (!member) {
        return <h2>Team Member Not Found</h2>;
    }

    return (
        <div className="team-member-page">
            <div className="main-header">
                <h1 className="team-title-main">Our Team</h1>
            </div>
            <div className="member-details">
                <img src={member.image} alt={member.name} className="member-image" />
                <h2 className="member-name">{member.name}</h2>
                {member.title && <h3 className="member-title">{member.title}</h3>}
                <p className="member-description">{member.description}</p>
            </div>
        </div>
    );
}

export default TeamMemberPage;
